import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="favourite"
export default class extends Controller {
  static targets = ["remove"];

  static values = {
    type: String,
    id: String
  };

  remove() {
    window.resetFavourites(this.typeValue, this.idValue);
  }
}
