import { Controller } from "@hotwired/stimulus";
import favouritesApi from "@/app/javascript/api/favourite";

const isFavourited = ({ type, id }) => {
  return !!window.favouritedListings.find(favourite => {
    return favourite[0] === type && favourite[1] === parseInt(id, 10);
  });
};

const updateFavourites = (ids, type, add) => {
  ids.forEach(id => {
    if (add) {
      if (!isFavourited({ type, id })) {
        window.favouritedListings.push([type, parseInt(id, 10)]);
        analytics.track("Product Added to Wishlist", {
          product_id: `PRODUCT-${id}`,
          sku: id
        });
      }
    } else {
      window.favouritedListings = window.favouritedListings.filter(
        favourite => {
          return !(favourite[0] === type && favourite[1] === parseInt(id, 10));
        }
      );
    }
  });
};

// Connects to data-controller="favourites"
export default class extends Controller {
  static targets = ["faveheart"];

  toggleFavourites(event) {
    const action = event.target.dataset.actionParam;
    this.performToggleFavourites(action);
  }

  async performToggleFavourites(action) {
    const ids = this.faveheartTargets.map(faveheart => faveheart.dataset.id);
    const { type } = this.faveheartTargets[0].dataset;

    if (action === "add") {
      await favouritesApi.batchAdd(ids, type);
      updateFavourites(ids, type, true);
      this.toggleButtons("add");
      window.dispatchEvent(new CustomEvent("addedFavourite"));
    } else if (action === "remove") {
      await favouritesApi.batchRemove(ids, type);
      updateFavourites(ids, type, false);
      this.toggleButtons("remove");
      window.dispatchEvent(new CustomEvent("removedFavourite"));
    }

    window.resetFavourites();
  }

  toggleButtons(action) {
    const addButton = this.element.querySelector(".add-favourites-button");
    const removeButton = this.element.querySelector(
      ".remove-favourites-button"
    );

    if (action === "add") {
      addButton.classList.add("hidden");
      removeButton.classList.remove("hidden");
    } else {
      addButton.classList.remove("hidden");
      removeButton.classList.add("hidden");
    }
  }
}
