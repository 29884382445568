import { application } from "./application";
import Favourite from "./favourite_controller";
import Favourites from "./favourites_controller";

application.register("favourite", Favourite);
application.register("favourites", Favourites);

const controllerMap = {
  "change-store": () => import("./change_store_controller"),
  cookie: () => import("./cookie_controller"),
  dialog: () => import("./dialog_controller"),
  drawer: () => import("./drawer_controller"),
  "dynamic-bundle": () =>
    Promise.all([
      {
        name: "dynamic-bundle",
        import: import("./dynamic_bundles/listing_controller")
      },
      {
        name: "dynamic-bundle-swatch",
        import: import("./dynamic_bundles/colour_swatch_controller")
      },
      {
        name: "dynamic-bundle-listing-image",
        import: import("./dynamic_bundles/listing_image_controller")
      }
    ]),
  dropdown: () => import("./dropdown_controller"),
  "flash-message": () => import("./flash_message_controller"),
  "form-submission": () => import("./form_submission_controller"),
  modal: () => import("./modal_controller"),
  popup: () => import("./popup_controller"),
  "show-password": () => import("./show_password_controller")
};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-controller]").forEach(element => {
    const controllerName = element.getAttribute("data-controller");
    const loader = controllerMap[controllerName];

    if (loader) {
      loader()
        .then(module => {
          if (Array.isArray(module)) {
            module.forEach(({ name, import: controller }) => {
              controller.then(ctrlModule => {
                application.register(name, ctrlModule.default);
              });
            });
          } else {
            application.register(controllerName, module.default);
          }
        })
        .catch(error => {
          console.error(`Error loading ${controllerName} controller:`, error);
        });
    }
  });
});
